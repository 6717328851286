import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Masonry from '../components/Masonry';
import Card from '../components/Card';
import Headline from '../components/Headline';
import Skills from '../components/Skills';
import Publications from '../components/Publications';
import Layout from '../components/Layout';
import './index.css';


export default ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <SEO title="Projects" />
        <Headline text="Projects" color="#5AF78E" />
        <div
          style={{
            padding: '30px 0px',
          }}
        >
          <Masonry>
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }) => (
                <Card
                  headline={post.frontmatter.title}
                  body={post.excerpt}
                  imageSizes={post.frontmatter.image.childImageSharp.sizes}
                  linkTo={post.frontmatter.path}
                  buttonText="View Project"
                />
              ))}
          </Masonry>
        </div>
    </Layout>
  );
};

/*
Index.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};
*/

export const pageQuery = graphql`
  query ProjectPageQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            image {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
